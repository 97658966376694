<template>
  <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
    <div class="row mt-4 mb-3" v-if="mode === 'create'">
      <div class="col-md-5 text-center actions">
          <input
            type="search"
            class="form-control"
            v-model="search.account_number"
            placeholder="Search by account number"
            @keyup.enter="searchCustomer()"
            required
          />
        </div>
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-primary"
            @click="searchCustomer()"
          >
            Search
          </button>
        </div>
        <div class="col-md-4"></div>
    </div>
    <form v-on:submit.prevent="handleSubmit(next)">
      <div class="row">

        <div class="col-md-12" v-if="newSettlement.customer_account_id">
          <!-- <label>Customer's Name: {{ newSettlement }}</label> -->
          <h4 class="my-4 cus-title">
            Customer's Name:
            {{
              customer_account.salutation +
                " " +
                customer_account.first_name +
                " " +
                customer_account.last_name
            }}
          </h4>

          <h4 class="cus-title">Select the Bill(s) from below for settlement: </h4>
          <div class="row">
            <div class="col-md-12">
              <table class="table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th class="text-center">Bill No</th>
                    <th class="text-center">Bill Month</th>
                    <th class="text-center">Issue Date</th>
                    <th class="text-center">Due Date</th>
                    <th class="text-center">Total Amount</th>
                  </tr>
                </thead>
                <tbody v-if="customer_account.bills.length">
                  <tr v-for="bill in customer_account.bills" :key="bill.id">
                    <td>
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name="bill_ids"
                          v-model="newSettlement.bill_ids"
                          :value="bill.id"
                        />
                      </div>
                    </td>
                    <td class="text-center">{{ bill.bill_number }}</td>
                    <td class="text-center">
                      {{ months[bill.bill_month] + ", " + bill.bill_year }}
                    </td>
                    <td class="text-center">
                      {{ dateFormat(bill.issue_date) }}
                    </td>
                    <td class="text-center">{{ dateFormat(bill.due_date) }}</td>
                    <td class="text-center">{{ formatPrice(bill.total_amount) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">No Due Bills</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <!-- <button type="button" class="btn btn-default btn-lg">
        Back
      </button> -->
      <div class="row">
        <div class="col-md-12 text-right">
          <button type="submit" class="btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
export default {
  name: "CustomerBillInfo",
  props: {
    settlement: Object,
    activeTab: Number,
  },
  data() {
    return {
      newSettlement: {},
      mode: 'create',
      search: {
        account_number: "",
        mode: this.mode
      },
      customer_account: { bills: [] },
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    next() {
      if (!this.newSettlement.bill_ids.length) {
        this.$toastr.e("You didn't select any bill yet.", "No Bill Selected!");
        return false;
      }

      let total = 0;
      _.forEach(this.customer_account.bills, (bill) => {
        if (_.includes(this.newSettlement.bill_ids, bill.id)) {
          total += bill.total_amount;
        }
      });
      this.newSettlement.total_amount = total;
      this.$emit("formSubmitted", { data: this.newSettlement, tab: 2 });
    },
    searchCustomer() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/admin/customer-accounts/due-bills`,
          this.search
        )
        .then(
          (res) => {
            this.setCustomerData(res.data.data);
            this.$store.dispatch("Settings/loading", false);
            this.newSettlement.customer_id = res.data.data.customer_id;
            this.newSettlement.customer_account_id = res.data.data.id;
          },
          (error) => {
            this.$toastr.e("Account number not found.", "Failed!");
            this.$store.dispatch("Settings/loading", false);
            this.resetCustomerData();
          }
        );
    },
    resetCustomerData() {
      this.newSettlement.customer_id = "";
      this.newSettlement.customer_account_id = "";
    },
    setCustomerData(data) {
      let fields = [
        "salutation",
        "first_name",
        "last_name",
        "customer_id",
        "bills",
        "id",
      ];
      for (let key in fields) {
        if (fields[key] === "id") {
          this.customer_account.customer_account_id = data[fields[key]];
        } else {
          this.customer_account[fields[key]] = data[fields[key]];
        }
      }
    },
    urlUpdate() {
      this.$router.replace({ name: "CreateSettlement", query: this.search });
    },
  },
  mounted() {
    this.newSettlement = { ...this.settlement };
    this.search.account_number = this.$route.query.account_number;
    this.mode = this.$route.query.mode?this.$route.query.mode:'create'
    if(this.mode === 'edit') {
      this.search.settlement_id = this.newSettlement.id
    }
    if (this.search.account_number) {
      this.searchCustomer();
    }

  },
  watch: {
    "search.account_number": {
      handler: function(after) {
        if (after === this.$route.query.account_number) return;
        this.$router.replace({
          name: "CreateSettlement",
          query: { account_number: after },
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.cus-title {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}
.table {
  th,
  td {
    border-top-color: #d8dbe0;
    color: #000;
    font-size: 14px;
  }
}
</style>
